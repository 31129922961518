<template>
<v-container class="pa-5 logbox">
      <v-row no-gutters justify="center">
     <v-col
      sm="5"
     >
       <v-form  
              
                ref="form"
                v-model="valid"
                lazy-validation
                style="width: 320px; margin: auto;"
              >
            <v-card style="margin-top: 20%;  background: none" class="pa-2" flat dark>
              <p class="text-center">
              <img :src="$store.state.systemlogo" width="150px"/>
              </p>
              <v-card-text>
                    
                      <v-text-field
                        v-model="username"
                        :rules="nameRules"
                        label="Username"
                        required
                        outlined
                      ></v-text-field>
                   
                      <v-text-field
                        v-model="password"
                        :rules="nameRules"
                        label="Password"
                        type="password"
                        v-on:keyup.enter="validate()"
                        required
                        outlined
                      ></v-text-field>
                    
              </v-card-text>
              <v-card-actions class="pt-4 pb-5">
                  <v-btn
                        color="primary"
                        block
                        rounded
                        large
                        :loading="loading"
                        @click="validate()"
                      >
                        LOGIN 
                      </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
           <v-col class="pt-4 text-center">
               <span style="color: #fff;">Don't have account yet?</span> <v-btn text @click="$router.push('/register').catch(err => {})" color="warning">Register here <v-icon right>mdi-arrow-right-bold-box</v-icon></v-btn>
        </v-col>
     </v-col>
    
   </v-row>
</v-container>
</template>
<script>
 import md5 from 'md5'
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      valid: true,
      loading: false,
      username: '',
      nameRules: [
        v => !!v || 'Field is required'
      ],
      password: '',
      snackbar: false,
    }),
    created() {
       this.PING()
        this.setLoggedIn(false)
    },
    mounted(){
      if (this.user != null) {
        this.setLoggedIn(true)
        this.$router.push('/dashboard')
      } else {
        this.CLEAR_SESSION()
      }
    },
    computed:{
       user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      
      validate () {
      if (this.$refs.form.validate()) {
         this.login()
       }
      },
      login(){
        this.loading=true
         var param = {
            Username: this.username,
            Password: md5(this.password)
          }
          this.$http.post("user/login",param).then(response => {
            console.log(response.data)
          if(response.data.status == true) {
            localStorage.user = JSON.stringify(response.data.account)
            localStorage.token = response.data.account.token
            localStorage.user_role = response.data.account.role
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
           // this.VA_ALERT('success', response.data.message) 
           // this.GET_SETTINGS()
           // this.UPDATE_RECORDS(response.data.account.id)
            setTimeout(()=>{
              this.setLoggedIn(true)
               this.loading = false
              this.$router.push('/dashboard')
            }, 1000)
          } else {
             this.loading = false
           this.VA_ALERT('error', response.data.message)
          }
           
          }).catch(e => {
            this.loading = false
              this.NETWORK_ERROR(e)
         })
        
          
      }
    },
  }
</script>